
import $x from "@/libs/$x.js";
export default {
  data() {
    return {};
  },
  created() {
    const shopId = $x.localStorage.getItem("SHOP_ID");
    if (shopId) {
      this.$store.state.user.shopId = shopId;
    }

    console.log("env-", process.env.ENV_CONFIG);
    console.log("platform-", process.env.PLATFORM_TYPE);

    this.injectDynamicStyles();

    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };

    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      const width = window.innerWidth;
      this.$store.dispatch("common/screenSizeChange", width);
    },
    injectDynamicStyles() {
      const lang = localStorage.getItem("lang");
      if (lang === "en") {
        const styles = `
          .el-form-item__label{
            width:auto!important;
          }
          .register-page .el-form-item__label{
            width:160px!important;
            line-height: 1.5!important;
          }
          .setting-block .column-form-component .setting-child{
            padding-left: 0px;
          }
          .advance-search-pc .time-select-button .el-button{
            width: auto!important;
          }
          .advance-search-pc .btn-group .el-button{
            width: auto!important;
          }
          .sec-selections .sec-label{
            white-space: nowrap;
          }
          .order-list-p-page .status-tab .status-tab-item{
            white-space: nowrap;
          }
          .customer-setting .form-item .item-label{
            width: auto!important;
          }
          .customer-setting .form-wrapper .desc {
            padding-left: 0px!important;
          }
          .tab-block .setting-block .block-inner .block-inner-item .block-inner-label{
            width: auto!important;
          }
          .appoint-create-form-pc{
            width: 550px!important;
          }
          .search-group .sec-selections .sec-item{
            padding-right:5px;
          }
          .decoration-form-item .el-form-item__content{
            margin-left: 120px!important;
          }
          .right-gp .view-all-staffdata-block .el-form-item__label{
            width: auto!important;
          }
          .el-date-editor.el-input{
            max-width: 200px!important;
          }
          .staff-roster-dialog .edit-form .edit-form-item .item-label{
            width:auto!important;
            white-space: nowrap;
          }
          .add-appointment-p-page .appoint-addForm-table{
            width: 600px!important;
          }
          .p-page .form-wrapper .el-textarea{
            width: auto!important;
            min-width: 500px!important;
          }
          .card-detail-p-page .cd-info-wrapper .card-info .li-label{
            white-space: nowrap;
            width: auto!important;
            padding-left: 10px!important;
          }
          .custom-card-detail-p-page .cd-info-wrapper .card-info .li-label{
            white-space: nowrap;
            width: auto!important;
            padding-left: 10px!important;
          }
          .stock-detail-p-page .base-info .row-info .row-info-item .row-label{
            white-space: nowrap;
            width: auto!important;
          }
          .product-detail-p-page .pd-detail-info .pd-label{
            white-space: nowrap;
            width: auto!important;
          }
          .product-eval-detail-p-page .pd-detail-info .pd-label{
            white-space: nowrap;
            width: auto!important;
          }
          .order-detail-p-page .order-detail-info .inner .inner-wrapper .inner-item .item-label{
            white-space: nowrap;
            width: auto!important;
          }
          .order-detail-p-page .customer-info .inner .customer-info-detail .info-item .item-label{
            white-space: nowrap;
            width: auto!important;
          }
          .order-detail-p-page .comsume-info .info-wrapper .table-desc .desc-list ul li .item-label{
            white-space: nowrap;
            width: auto!important;
          }
          .order-detail-p-page .btn-group .el-button{
            width: auto!important;
          }
          .order-remark-dialog .label{
            white-space: nowrap;
            width: auto!important;
          }
          .create-order-p-page .order-create-page .order-detail .list-table .ex-select{
            word-break: auto-phrase;
            white-space: normal;
            line-height: 1.2;
          }
          .create-order-p-page .order-create-page .order-more-info .sp-form .sp-item .sp-label{
            white-space: nowrap;
            width: auto!important;
          }
          .create-order-p-page .order-end-handle .el-button{
            width: auto!important;
          }
          .order-detail-p-page .el-button{
            width: auto!important;
          }
          .order-detail-p-page .logist-info .inner .logist-info-detail .info-item .item-label{
            white-space: nowrap;
            width: auto!important;
          }
          .logistics-block .label-inner{
            width: auto!important;
          }
          .logistics-block .el-upload--picture-card{
            max-width:200px;
          }
          .layer-p-page .el-table .el-table__row .cell{
            word-break: break-all!important;
            white-space: normal!important;
            line-height: 1.3!important;
          }
          .pd-detail-info .pd-detail-item{
            width: 100%!important;
          }
          .pd-detail-info .pd-detail-item .pd-label{
            width: auto!important;
          }
          .un-avail {
            min-width: 40px;
            width: auto!important;
          }
          .row-input .unit{
            white-space: nowrap;
          }
          .layer-p-page .custom-card-detail-p-page .cd-baseinfo .card-style{
            width: 5.98rem;
            height: 2.36rem;
          }
          .layer-p-page .custom-card-detail-p-page .cd-baseinfo .card-style .more{
            text-align: right;
          }
          .layer-p-page .custom-card-detail-p-page .cd-baseinfo .card-style .more .beni-price{
            width:auto;
            padding:0rem 0.1rem;
          }
        `;
        const styleElement = document.createElement("style");
        styleElement.textContent = styles;
        document.head.appendChild(styleElement);
      }
      if (lang === "en") {
        const styles = `
          .el-range-separator{
            overflow: hidden;
          }
          .el-range-separator::before{
            content:"~ ";
          }
        `;
        const styleElement = document.createElement("style");
        styleElement.textContent = styles;
        document.head.appendChild(styleElement);
      }
      if ($x.isTablet || $x.isPc) {
        let baseColor = "";
        if (process.env.PLATFORM_TYPE === "ec") {
          baseColor = "#ffd102";
        } else if (process.env.PLATFORM_TYPE === "emall") {
          baseColor = "#eb3443";
        } else if (process.env.PLATFORM_TYPE === "pet") {
          baseColor = "#ca8eee";
        }

        let styles = `
          .layer-p-page .create-order-p-page .order-create-page .customer-inner .inner-tab .tab-change .tab-change-item{
            background: #ebebeb;
            color:#606266;
            white-space: normal;
            height: auto;
            width: auto;
            padding: 0.05rem 0.2rem;
            line-height: 1.2;
            display:flex;
            align-items:center;
            justify-content:center;
          }
          .layer-p-page .create-order-p-page .order-create-page .customer-inner .inner-tab .tab-change .tab-change-item + .tab-change-item{
            margin-left: 0.1rem;
          }
          .layer-p-page .create-order-p-page .order-create-page .customer-inner .inner-tab .tab-change .tab-change-item.actived{
            color: #fff;
            background: ${baseColor};
          }
          .layer-p-page .staff-list-container{
            padding: 0.15rem!important;
          }
          .layer-p-page .list-inner{
            padding: 0.15rem!important;
          }
          .layer-p-page .search-top{
            padding: 0.15rem 0.15rem 0.1rem 0.15rem!important;
          }
          .layer-p-page .work-attendance-p-page .work-block-1{
            padding: 0.15rem!important;
          }
          .layer-p-page .performance-gains-p-page .tab-block-0 .setting-block{
            padding: 0 0.15rem!important;
          }
          .layer-p-page .promoter-console-pc-page .cashout-container{
            padding: 0.15rem!important;
          }
          .layer-p-page .promoter-console-pc-page .cashout-block-wrapper{
            margin-top:0.15rem!important;
          }
          .layer-p-page .performance-gains-p-page .tab-block-0 .setting-block{
            margin-top:0.2rem!important;
          }
          .layer-p-page .appointment-setting-p-page .setting-inner{
            padding: 0 0.15rem!important;
          }
          .layer-p-page .pd-muti-btn{
            bottom: 0.25rem!important;
          }
        `;
        if ($x.isTablet) {
          styles += `
            .layer-p-page .layer-container .menu-container{
              position:fixed;
              top:0.95rem;
              left:0px;
              height: calc(100vh - 0.95rem);
              height: calc(100dvh - 0.95rem);
              width:1.25rem;
            }
            .layer-p-page .layer-container .submenu-container{
              position:fixed;
              top:0.95rem;
              left:1.25rem;
              height: calc(100vh - 0.95rem);
              height: calc(100dvh - 0.95rem);
              width:1.25rem;
            }
            `;
        }
        const styleElement = document.createElement("style");
        styleElement.textContent = styles;
        document.head.appendChild(styleElement);

        const groupPageClasses = [
          "order-list-p-page",
          "card-order-list-p-page",
          "service-order-list-p-page",
        ];
        groupPageClasses.forEach((className) => {
          let styles = `
            .layer-p-page .${className} .status-tab{
              padding: 0.12rem 0.12rem;
              background: #fff;
              margin:0.1rem 0rem;
            }
            .layer-p-page .${className} .search-group{
              padding: 0.12rem 0.15rem;
            }
            .layer-p-page .${className} .order-block-p-component{
              padding: 0.1rem 0.15rem 0.15rem 0.15rem;
              margin-bottom:0.1rem;
            }
            .layer-p-page .${className} .order-shop-block-p-component{
              padding: 0.1rem 0.15rem 0.15rem 0.15rem;
              margin-bottom:0.1rem;
            }
            .layer-p-page .${className} .order-block-p-component .order-table{
              margin-top: 0.1rem;
            }
            .layer-p-page .${className} .order-shop-block-p-component .order-table{
              margin-top: 0.1rem;
            }
            .layer-p-page .${className} .order-block-p-component .order-table .el-table{
              width: 100%!important;
            }
            .layer-p-page .${className} .order-shop-block-p-component .order-table .el-table{
              width: 100%!important;
            }
            .layer-p-page .${className} .status-tab .status-tab-item{
              background: #ebebeb;
              white-space: normal;
              height: auto;
              padding: 0.05rem 0.02rem;
              line-height: 1.2;
              display:flex;
              align-items:center;
              justify-content:center;
              min-height: 0.3rem;
            }
            .layer-p-page .${className} .status-tab .status-tab-item + .status-tab-item{
              margin-left: 0.1rem;
            }
            .layer-p-page .${className} .status-tab .status-tab-item.actived{
              color: #fff;
              background: ${baseColor};
            }
          `;
          if ($x.isTablet) {
            styles += `
            .layer-p-page .${className} .search-group .more-top .el-input{
              width: 1.3rem!important;
            }
            .el-table .el-table__header-wrapper .el-table__cell{
              padding: 0.06rem 0!important;
            }
            `;
          }
          const styleElement = document.createElement("style");
          styleElement.textContent = styles;
          document.head.appendChild(styleElement);
        });
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
