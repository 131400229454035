
export default {
  props: {
    number: {
      type: [String, Number, Boolean],
      default: "",
    },
    margin: {
      type: [Number],
      default: 8,
    },
    actived: {
      type: [Number],
      default: 0,
    },
    total: {
      type: [Number],
      default: 3,
    },
    type: {
      type: [String, Number, Boolean],
      default: 1,
    },
    align: {
      type: [String, Number, Boolean],
      default: "left",
    },
    color: {
      type: [String, Number, Boolean],
      default: "#f44",
    },
  },
  data() {
    return {};
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  /**
   * 页面渲染前
   * @return {[type]} [description]
   */
  created() {},
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面加载执行
   * @return {[type]} [description]
   */
  async mounted() {},
  methods: {},
};
