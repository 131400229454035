import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const downloadPDF = async (page, name) => {
  const canvas = await html2canvas(page, {
    onclone: function (clonedDoc) {
      return new Promise(resolve => {
        let images = clonedDoc.getElementsByTagName('img');
        let loaded = 0;
        for (let img of images) {
          if (img.complete) {
            loaded++;
          } else {
            img.onload = () => {
              loaded++;
              if (loaded == images.length) resolve();
            }
          }
        }
        if (loaded == images.length) resolve();
      });
    },
    useCORS: true,
    allowTaint: true
  });
  canvas2PDF(canvas, name);
};

const canvas2PDF = async (canvas, name) => {
  let contentWidth = canvas.width;
  let contentHeight = canvas.height;

  let pageWidth = 595.28;
  let pageHeight = 841.89;
  let imgWidth = pageWidth;
  let imgHeight = pageWidth / contentWidth * contentHeight;

  let pdf = new jsPDF("p", "pt", "a4");
  
  let pageCount = Math.ceil(imgHeight / pageHeight);

  for (let i = 0; i < pageCount; i++) {
    if (i > 0) {
      pdf.addPage();
    }
    pdf.addImage(
      canvas.toDataURL("image/jpeg", 1.0),
      "JPEG",
      0,
      -i * pageHeight,
      imgWidth,
      imgHeight
    );
  }

  pdf.save(`${name}.pdf`);
};

export default {
  downloadPDF
}