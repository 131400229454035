import Vue from "vue";
import VueI18n from "vue-i18n";
import zh from "@/i18n/langs/zh";
import en from "@/i18n/langs/en";

Vue.use(VueI18n);

const lang = "en";

localStorage.setItem("lang", lang);

const i18n = new VueI18n({
  locale: lang,
  messages: {
    zh: zh,
    en: en,
  },
  silentTranslationWarn: true,
});

const $t = function (key, values) {
  return i18n.t(key, values);
};

Vue.prototype.$t = $t;
Vue.prototype.$lang = Vue.$lang = lang;
window.$t = $t;

export { i18n, $t, lang };
