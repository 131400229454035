
import Upload from "./upload.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapMutations } = createNamespacedHelpers("photo");
export default {
  components: {
    Upload,
  },
  props: {
    fileType: {
      type: [String, Number, Boolean, Object, Array],
      default: 1,
    },
    /**
     * 上传限制
     * @type {Number}
     */
    limit: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 1,
    },
  },
  provide() {
    return {
      uploader: this,
    };
  },
  data() {
    return {
      imageUploadUrl: "",
      baseOrginUrl: "",
      index: -1,
      /**
       * 编辑ID
       * @type {Number}
       */
      is_edit: 0,
      /**
       * 选中值
       * @type {Array}
       */
      checkedData: [],
      /**
       * 是否全选
       * @type {Boolean}
       */
      checkAll: false,
      /**
       * 半选中状态
       * @type {Boolean}
       */
      isIndeterminate: false,
      /**
       * 数据加载
       * @type {Boolean}
       */
      loading: false,
      /**
       * 处理分级
       */
      breadcrumb: [
        {
          id: -1,
          name: this.$t('quan-bu'),
        },
      ],
    };
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {
    index(value) {
      //this.checkedData = [];
      let groupInfo = null;
      if (value == -1) {
        groupInfo = {
          id: -1,
          name: this.$t('quan-bu'),
        };
      } else {
        groupInfo = this.groupList[value];
      }
      this.breadcrumb = [groupInfo];
      //设置当前分组信息
      this.setGroupInfo(groupInfo);
      //加载当前分组数组
      this.LoadPhotoList({
        id: groupInfo.id,
        shopId: this.$store.state.user.shopId,
        current: 1,
        pageSize: this.pageSize,
      });
    },
    checkedData(value) {
      if (value.length == this.photoList.length) {
        this.isIndeterminate = false;
        this.checkAll = true;
      } else {
        if (value.length > 0) {
          this.isIndeterminate = true;
        } else {
          this.checkAll = false;
          this.isIndeterminate = false;
        }
      }
    },
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    ...mapState(["groupList", "groupInfo", "photoList", "pageInfo"]),
    /**
     * 处理当前要获取调试
     * @return {[type]} [description]
     */
    pageSize() {
      return this.limit > 1 ? 10 : 15;
    },
    inputType() {
      return this.max > 1 ? "checkbox" : "radio";
    },
    /**
     * 是否创建
     * @return {Boolean} [description]
     */
    is_make() {
      if (this.groupInfo.name === this.$t('quan-bu') || this.groupInfo.name === this.$t('wei-fen-zu')) {
        return false;
      }
      let path = this.groupInfo.path || this.groupInfo.url || "0";
      return path.split("-").length < 3;
    },
    /**
     * 移动
     * @return {Boolean} [description]
     */
    is_move() {
      if (this.checkedData.length < 1) {
        return true;
      }
      let s = JSON.stringify(this.checkedData);
      return s.indexOf('"type":0') > -1;
    },
    /**
     * 是否删除
     * @return {Boolean} [description]
     */
    is_delete() {
      if (this.checkedData.length < 1) {
        return true;
      }
      let s = JSON.stringify(this.checkedData);
      if (s.indexOf('"type":0') > -1) {
        if (this.checkedData.length > 1) {
          return true;
        }
      }
      return s.indexOf('"type":1') > -1 && s.indexOf('"type":0') > -1;
    },
    /**
     * 是否重命名
     * @return {Boolean} [description]
     */
    is_rename() {
      if (this.checkedData.length === 0) {
        return true;
      }
      if (this.checkedData.length > 1) {
        return true;
      }
      let s = JSON.stringify(this.checkedData);
      return s.indexOf('"type":1') > -1 && s.indexOf('"type":0') > -1;
    },
  },
  /**
   * 页面加载执行
   * @return {[type]} [description]
   */
  created() {
    this.baseOrginUrl = this.$x.getHttpBaseUrl();
    this.imageUploadUrl =
      this.baseOrginUrl +
      "/platform/" +
      this.$store.state.user.shopId +
      "/media/management/0";
  },
  async mounted() {
    this.initialize();
  },
  methods: {
    ...mapMutations(["setGroupInfo"]),
    ...mapActions([
      "LoadGroupList",
      "LoadPhotoList",
      "InterGroupItem",
      "UpdateGroupItem",
      "DeleteGroupItem",
      "DeletePhotoList",
      "MovePhotoList",
      "UpdatePhotoItem",
      "upadteGroupInfo",
    ]),
    initialize() {
      // setTimeout(() => {
      //   this.LoadGroupList().then(() => {
      //     this.LoadPhotoList({ id: -1, current: 1, pageSize: this.pageSize });
      //   });
      // }, 1000);
      this.LoadPhotoList({
        id: -1,
        current: 1,
        pageSize: this.pageSize,
        shopId: this.$store.state.user.shopId,
      });
    },
    /**
     * 执行创建分组
     * @return {[type]} [description]
     */
    handleMakeGroup(option, type = 1) {
      this.loading = true;
      this.InterGroupItem(option)
        .then((value) => {
          if (type == 1) {
            this.index = -1;
            this.upadteGroupInfo({
              id: -1,
              name: this.$t('quan-bu'),
            });
            //重载分组列表
            this.LoadGroupList().then(() => {});
          }
          if (type == 2) {
            setTimeout(() => {
              let id = value.parent_id || this.groupInfo.id;
              //重载当前分组列表
              this.LoadPhotoList({
                id: id,
                shopId: this.$store.state.user.shopId,
                current: 1,
                pageSize: this.pageSize,
              }).then(() => {
                this.loading = false;
              });
              //this.checkedData = [];
            }, 500);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /**
     * 执行删除数据
     * @return {[type]} [description]
     */
    handleGroupDelete(id, type = 1) {
      this.loading = true;
      this.$confirm(
        this.$t('ci-fen-zu-ji-zi-ji-fen-zu-de-tu-pian-du-jiang-yi-zhi-wei-fen-zu-zhong-shi-fou-que-ren-shan-chu'),
        this.$t('ti-shi-0'),
        {
          confirmButtonText: this.$t('que-ding'),
          cancelButtonText: this.$t('qu-xiao-0'),
          type: "warning",
        }
      )
        .then(() => {
          this.DeleteGroupItem({ id, type })
            .then(() => {
              if (type == 1) {
                setTimeout(() => {
                  //重载当前分组列表
                  this.LoadPhotoList({
                    id: this.groupInfo.id,
                    shopId: this.$store.state.user.shopId,
                    current: 1,
                    pageSize: this.pageSize,
                  }).then(() => {
                    this.loading = false;
                  });
                }, 500);
              }
              if (type == 2) {
                this.index = -1;
                this.LoadGroupList().then(() => {
                  this.loading = false;
                  this.LoadPhotoList({
                    id: -1,
                    shopId: this.$store.state.user.shopId,
                    current: 1,
                    pageSize: 15,
                  });
                });
              }
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /**
     * 处理上传
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    uploadFile(e) {
      //此处执行POST提交方法
      return new Promise(async (reslove, reject) => {
        //将文件流数据转Base64数据
        // var oFReader = new FileReader();
        // oFReader.readAsDataURL(e);
        // oFReader.onload = (oFREvent) => {
        //   console.log(e);
        // };
        var formData = new FormData();
        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        formData.append("file", e, e.name);
        console.log(formData);
        const { data, code, message } = await this.$x.post(
          `/platform/${this.$store.state.user.shopId}/media/management/0`,
          formData,
          config
        );
        if (code === 200) {
          this.$x.toast.success(message);
          // this.formInfo.data.userLogoUrl = data[1];
          this.LoadPhotoList({
            id: -1,
            shopId: this.$store.state.user.shopId,
            current: this.pageInfo.current,
            pageSize: this.pageSize,
          }).then(() => {});
          reslove()
        } else {
          this.$x.toast.error(message);
          reject()
        }
      });
    },
    uploadOK() {
      let _this = this;
      setTimeout(function () {
        _this.handleSizeChange();
      }, 1000);
    },
    /**
     * 数据全选
     * @return {[type]} [description]
     */
    handleAllChange(value) {
      this.isIndeterminate = false;
      if (value) {
        this.checkedData = this.photoList;
      }
    },
    /**
     * 分页
     * @return {[ty3pe]} [description]
     */
    handleSizeChange(mumber = 1) {
      //重载当前分组列表
      this.LoadPhotoList({
        id: this.groupInfo.id,
        shopId: this.$store.state.user.shopId,
        current: mumber,
        pageSize: this.pageSize,
      });
    },
    /**
     * 执行删除数据
     * @return {[type]} [description]
     */
    handleDeletes() {
      this.loading = true;
      let arr = [];
      let type = 0;
      for (let index in this.checkedData) {
        let item = this.checkedData[index];
        if (item.id) {
          type = item.type;
          arr.push(item.id);
        }
      }
      if (type === 0) {
        if (arr[0]) {
          let id = arr[0];
          this.handleGroupDelete(id);
        }
      } else {
        this.$confirm(this.$t('shi-fou-que-ren-shan-chu-xuan-zhong-nei-rong'), this.$t('ti-shi-0'), {
          confirmButtonText: this.$t('que-ding'),
          cancelButtonText: this.$t('qu-xiao-0'),
          type: "warning",
        })
          .then(() => {
            //处理素材删除
            this.DeletePhotoList(arr).then(() => {
              //重载当前分组列表
              this.LoadPhotoList({
                id: this.groupInfo.id,
                shopId: this.$store.state.user.shopId,
                current: 1,
                pageSize: this.pageSize,
              }).then(() => {
                this.loading = false;
              });
            });
          })
          .catch(() => {});
      }
    },
    /**
     * 照片移动至
     * @return {[type]} [description]
     */
    handleMoveList(group_id) {
      let _array = [];
      this.checkedData.map((item) => {
        _array.push(item.id);
      });
      this.MovePhotoList({ list: _array, group_id }).then(() => {
        //重载当前分组列表
        this.LoadPhotoList({
          id: this.groupInfo.id,
          shopId: this.$store.state.user.shopId,
          current: 1,
          pageSize: this.pageSize,
        });
      });
    },
    /**
     * 内容重命名
     * @return {[type]} [description]
     */
    handleRenameItem(data) {
      if (data.type == 0) {
        this.handleEditClassify(data).then(() => {
          //重载当前分组列表
          this.UpdateGroupItem({
            id: this.groupInfo.id,
            current: this.pageInfo.current,
            pageSize: this.pageSize,
          });
        });
      } else {
        if (data.id) {
          this.UpdatePhotoItem(data).then(() => {
            //重载当前分组列表
            this.LoadPhotoList({
              id: this.groupInfo.id,
              shopId: this.$store.state.user.shopId,
              current: this.pageInfo.current,
              pageSize: this.pageSize,
            });
          });
        }
      }
    },
    /**
     * 双击点击事件
     * @return {[type]} [description]
     */
    handleDblClick(value) {
      //执行数据加载
      // if (value.type === 0) {
      //   //设置当前分组信息
      //   this.setGroupInfo(value);
      //   //面包屑
      //   this.breadcrumb.push(value);
      //   //重载当前分组列表
      //   this.LoadPhotoList({
      //     id: value.id,
      //     shopId: this.$store.state.user.shopId,
      //     current: 1,
      //     pageSize: this.pageSize,
      //   });
      // }
    },
    /**
     * 处理样式数据
     * @return {[type]} [description]
     */
    handleCrumbItem(value, index) {
      if (index === 0) {
        this.upadteGroupInfo(value);
        this.breadcrumb = [value];
        //重置图片数据信息
        this.LoadPhotoList({
          id: value.id,
          shopId: this.$store.state.user.shopId,
          current: 1,
          pageSize: this.pageSize,
        });
      }
      if (index === 1) {
        if (this.breadcrumb.length > 2) {
          this.breadcrumb.pop();
        }
        let value = this.breadcrumb[1];
        this.upadteGroupInfo(value);
        //重置图片数据信息
        this.LoadPhotoList({
          id: value.id,
          shopId: this.$store.state.user.shopId,
          current: 1,
          pageSize: this.pageSize,
        });
      }
    },
  },
};
