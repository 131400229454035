import $x from '@/libs/$x'

export default {
  namespaced: true,
  state: {
    routeQuery: {},
  },
  mutations: {
    /**
     * 设置路径
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setRouteQuery(state, query) {
      state.routeQuery = query;
    }
  },
  actions: {
  }
};
