
import Detail from "./detail.vue";
export default {
  components: {
    Detail,
  },
  provide() {
    return {
      uploader: this,
    };
  },
  props: {
    /**
     * [visible description]
     * @type {Object}
     */
    visible: {
      type: Boolean,
      default: false,
    },
    /**
     * 执行的上传方法
     * @type {Object}
     */
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  /**
   * 页面渲染前
   * @return {[type]} [description]
   */
  created() {},
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面加载执行
   * @return {[type]} [description]
   */
  async mounted() {},
  methods: {
    /**
     * 处理点击事件
     * @return {[type]} [description]
     */
    handleClick() {
      this.selectPicture = "";
      this.dialogVisible = true;
    },
    /**
     * 处理关闭事件
     * @return {[type]} [description]
     */
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close", "");
    },
    /**
     * 处理确认事件
     * @return {[type]} [description]
     */
    handleConfirm() {
      this.dialogVisible = false;
      let selectPicture = "";
      if (this.$refs["detail"].checkedData) {
        if (this.limit > 1) {
          selectPicture = [];
          let _array = this.$refs["detail"].checkedData;
          for (let index in _array) {
            let item = _array[index];
            if (item.mediaPath) {
              selectPicture.push({
                url: item.mediaPath,
                cover:
                  item.mediaPath +
                  "?x-oss-process=video/snapshot,t_1000,m_fast",
              });
            }
          }
        } else {
          let item = this.$refs["detail"].checkedData;
          if (item.mediaPath) {
            selectPicture = {
              url: item.mediaPath,
              cover:
                item.mediaPath + "?x-oss-process=video/snapshot,t_1000,m_fast",
            };
          }
        }
      }
      console.log(selectPicture);
      this.$emit("confirm", selectPicture);
    },
  },
  render(h) {
    let { dialogVisible, handleClick, handleClose, handleConfirm } = this;
    //判断插槽是否存在DOM结构，如果存在则获取后放入upload目录
    const trigger = this.$slots.trigger || this.$slots.default;

    //设置上传样式
    const defaultData = {
      class: "photo-solt",
      on: {
        click: handleClick,
      },
    };

    const dialogData = {
      props: {
        visible: dialogVisible,
        title: this.$t('xuan-ze-shi-pin'),
        "before-close": handleClose,
        width: "1090px",
        "destroy-on-close": true,
      },
    };

    const DetailData = {
      props: {
        limit: this.limit,
      },
      ref: "detail",
    };

    const cancel = this.$t('qu-xiao-1');
    const confirm = this.$t('que-ding-0');

    return (
      <div class="video-dialog">
        <div {...defaultData}>{trigger}</div>
        <el-dialog {...dialogData}>
          <Detail {...DetailData}></Detail>
          <template slot="footer">
            <el-button size="large" on-click={handleClose}>
              {cancel} </el-button>
            <el-button size="large" type="primary" on-click={handleConfirm}>
              {confirm} </el-button>
          </template>
        </el-dialog>
      </div>
    );
  },
};
