//映射表
export default {
  manageMenuMapper: {
    2: {
      path: '/group',
    },
    3: {
      path: '/group/admingroup'
    },
    4: {
      path: '/group/adminuser',
    },
    5: {
      path: '/bussiness',
    },
    6: {
      path: '/bussiness/businessGroup',
    },
    7: {
      path: '/bussiness/businessUser',
    },
    8: {
      path: '/tutor',
    },
    9: {
      path: '/tutor/tutorLevel',
    },
    10: {
      path: '/tutor/tutorUser',
    },
    15: {
      path: '/tutor/tutorServer',
    },
    22: {
      path: '/tutor/tutorArrange',
    },
    23: {
      path: '/service',
    },
    24: {
      path: '/service/servicerUser',
    },
    25: {
      path: '/advert',
    },
    26: {
      path: '/advert/advertiserUser',
    },
    28: {
      path: '/advert/advertiserAccount',
    },
    27: {
      path: '/advert/advertiserWx',
    },
    11: {
      path: '/customer',
    },
    29: {
      path: '/customer/customerInfo',
    },
    12: {
      path: '/customer/appointManage',
    },
    18: {
      path: '/store',
    },
    19: {
      path: '/store/storeType',
    },
    21: {
      path: '/store/storeRecord',
    },
    20: {
      path: '/store/storeList',
    },
    30: {
      path: '/tutor/serverType',
    },
    31: {
      path: '/tutor/assessManage',
    },
    32: {
      path: '/tutor/tutorGroup',
    },
    36: {
      path: '/course',
    },
    44: {
      path: '/statistic',
    },
    37: {
      path: '/course/courseManage',
    },
    38: {
      path: '/course/courseApply',
    },
    45: {
      path: '/statistic/tutorStatistics',
    },
    40: {
      path: '/customer/customerKind',
    },
    41: {
      path: '/customer/customerRecord',
    },
    46: {
      path: '/statistic/businessStatistic',
    },
    47: {
      path: '/myNotification',
    },
    48: {
      path: '/myNotification/index',
    }
  }
}
