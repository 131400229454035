import $x from '@/libs/$x'

const urlMapper = function (name, value) {
  let url = ''
  switch (name) {
    case 'serviceType':
      url = `/pages/serviceList/index?type=${value}`
      break;
    case 'serviceLabel':
      url = `/pages/serviceList/index?label=${value}`
      break;
    case 'serviceDetail':
      url = `/pages/serviceDetail/index?id=${value}`
      break;
    case 'cardType':
      url = `/pages/cardList/index?type=${value}`
      break;
    case 'cardDetail':
      url = `/pages/cardDetail/index?id=${value}`
      break;
    case 'goodType':
      url = `/pages/goodList/index?type=${value}`
      break;
    case 'goodLabel':
      url = `/pages/goodList/index?label=${value}`
      break;
    case 'goodDetail':
      url = `/pages/goodDetail/index?id=${value}`
      break;
    default:
      url = ''
      break;
  }
  return url
}

let labelBase = ''
if (process.env.ENV_CONFIG === 'test') {
  labelBase = `http://h5.dev.${process.env.PLATFORM_TYPE === 'ec' ? 'ec.' : ''}mallpraise.com.cn`
} else {
  labelBase = `http://h5.${process.env.PLATFORM_TYPE}.mallpraise.com.cn`
}

export default {
  namespaced: true,
  state: {
    h5Origin: labelBase,
  },
  getters: {
    urlLink: (state, getter, root) => {
      return function (name, value) {
        const result = urlMapper(name, value)
        if (result) {
          return state.h5Origin + result + `&shopCode=${root.user.shopCode}`
        }
        return ''
      }
    }
  },
  mutations: {
  },
  actions: {
  }
};
