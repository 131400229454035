var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pointer",style:({
    marginBottom: _vm.margin + 'px',
    marginLeft: _vm.margin / 2 + 'px',
  })},[(_vm.type < 3)?_c('div',{staticClass:"pointer-content",class:{
      circle: _vm.type == 1,
      strip: _vm.type == 2,
    },style:({
      textAlign: _vm.align,
    })},_vm._l((_vm.total),function(item,index){return _c('span',{key:index,staticClass:"pointer-item",class:{ active: _vm.actived == index },style:({
        'background-color': _vm.actived == index ? _vm.color : undefined,
      })})}),0):_vm._e(),_vm._v(" "),(_vm.type == 3)?_c('div',{staticClass:"pointer-text",style:({
      textAlign: _vm.align,
    })},[_c('span',[_vm._v(_vm._s(_vm.actived + 1 + "/" + _vm.total))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }