/*
自定义的axios 第3个参数（config）字段意义：
{
showError:   默认弹出toast框提示。showError='alert'表示弹出alert框。showError===false默认不弹出
noToken：    true表示请求时，不在header中带入token。默认带入
maskOptions:  表示请求时显示遮罩层的选项。默认{body: true}
}

*/

import axios from 'axios'
import appConfig from '../../config/app-config'
import msgDlg from './utils/msgDialog'
// import loading from './utils/loading'
import $x from '@/libs/$x.js'
import Qs from 'qs'

var serverMap = require('../../config/serverMap.js')

// 超时时间
// axios.defaults.timeout = 8000
axios.defaults.baseURL = serverMap.base
// axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.withCredentials = true
axios.defaults.timeout = 5*60000 //20秒，超时报错

// axios.defaults.transformRequest = function (request) {
//   return JSON.stringify(request)
// }

const CoveredErrMsg = '系统超时，请稍后重试'
// http请求拦截器
axios.interceptors.request.use(function (config) {
  if (!config.noToken) {
    let userInfo = $x.localStorage.getItem('USER_INFO');
    if (userInfo && userInfo.accessToken) {
      config.headers['Authorization'] = userInfo.type + ' ' + userInfo.accessToken
    }
  }

  //调试
  // console.log(config.url)

  if (config.data) {
    console.log($x.deepCopy(config.data))
  } else {
    try {
      // console.log(Qs.parse(config.url.split('?')[1]))
    } catch (err) {
      console.log(err)
    }
  }

  //params中存在对象则用json的方式传
  let hasObj = false
  if (config.data && config.method === 'post' && Object.prototype.toString.call(config.data) != '[object FormData]') {
    if (Object.prototype.toString.call(config.data) === "[object Object]" && Object.keys(config.data).length === 1) {
      let strType = Object.prototype.toString.call(Object.values(config.data)[0])
      if (strType === "[object Object]" || strType === "[object Array]") {
        hasObj = true
      }
    }
    if (hasObj) {
      config.headers['Content-Type'] = 'application/json'
      const targetName = Object.keys(config.data)[0]
      if (targetName) {
        config.data = config.data[targetName]
      }
      config.data = JSON.stringify(config.data);
    } else {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      config.data = Qs.stringify(config.data);
    }
  }

  if($x.isTablet){
    // 修改 get 请求的 pageSize 参数
    if (config.method === 'get') {
      let [baseUrl, queryString] = config.url.split('?');
      if (queryString) {
        let params = Qs.parse(queryString);
        if (params.pageSize === '10') {
          params.pageSize = '6';
          config.url = `${baseUrl}?${Qs.stringify(params)}`;
        }
      }
    }
  }

  //将 {node_api}/xxx/yyy 的url替换为对应服务的前缀
  config.url = config.url.replace(/^\{(\w+)\}/, (m, $1) => serverMap[$1] || '');

  //遮罩层
  // loading.show(config.maskOptions)

  return config
}, fail)

// http响应拦截器
axios.interceptors.response.use(function (res) {
  // loading.close(res.config.maskOptions)
  var data = res.data || {};
  // var retCode = Number(data.status);
  // console.log('retCode', data)

  if(data.code === 401) {
    $x.localStorage.removeItem('USER_INFO')
    window.location.reload()
    return
  }

  return data
}, fail);

function doLogin(response) {
  return new Promise((resolve, reject) => {
    msgDlg.alert(response.data, '提示', {
      callback: action => {
        // var url = appConfig.LOGIN_PATH
        // var path = location.href.match(/https?:\/\/[^\/]+(\/.+)/i)[1]
        // if (path && path !== '/main.html#/') {
        //     url += url.indexOf('?') > -1 ? '&' : '?'
        //     url += 'redirectUrl=' + encodeURIComponent(path)
        // }
        $x.localStorage.removeItem('USER_INFO')
        window.location.reload()
        resolve()
      }
    })
  });
}

function fail(error) {
  if (error.response.status === 404) {
    msgDlg.toast.error('系统错误！');
  }
  if (error.response && error.response.status === 401) { // token失败
    $x.localStorage.removeItem('USER_INFO')
    window.location.reload()
  }
  // if (error.config) loading.close(error.config.maskOptions);

  // if (!error.errmsg) error.errmsg = CoveredErrMsg;
  // showErr(error.config, CoveredErrMsg);
  // if (error.config.url && error.config.url.indexOf('system/badRequest') === -1) {
  //   axios.post('/system/badRequest', {
  //     response: error.response
  //   }, {
  //     "Content-Type": 'application/json'
  //   })
  // }
  return Promise.reject(error)
}

function showErr(config, errmsg) {
  if (config && config.showError === 'alert')
    msgDlg.alert(errmsg, {
      type: 'error'
    });
  else
    msgDlg.toast.error(errmsg);
}

export default axios
