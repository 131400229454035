import $x from '@/libs/$x'

export default {
  namespaced: true,
  state: {
    kinds: [],
  },
  mutations: {
    setKinds: (state, data) => {
      state.kinds = data
    },
  },
  actions: {
    getKinds({
      commit,
      dispatch,
      getters
    }) {
      return new Promise(async (resolve, reject) => {
        const {
          data,
          code,
          message
        } = await $x.get('/expert/kind')
        if (code === 200) {
          if (data) {
            var hansd = data.map(item => {
              return {
                key: item.title,
                value: item.id
              }
            })
            commit('setKinds', hansd)
            resolve()
          }
        } else {
          reject(message)
        }
      })
    }
  }
}
