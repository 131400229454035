import $x from '@/libs/$x.js'

const initView = (pcView, mobileView, ...mixin) => {
  let mainView = pcView
  let isMobile = $x.isMobile
  if (isMobile) {
    mainView = mobileView
  } else {
    mainView = pcView
  }
  if (mixin.length > 0) {
    mainView.mixins = mainView.mixin ? [...mainView.mixin, ...mixin] : mixin
  }
  //注入platformInit
  if (mainView.mixins && mainView.mixins.length > 0) {
    mainView.mixins.forEach(item => {
      if (item.methods && item.methods.platformInit) {
        item.beforeMount = function () {
          this.compatible = {}
          this.platformInit()
        }
      }
    })
  }
  return mainView
}
export default initView
