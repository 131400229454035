import router, {
  resetRouter,
  shopRoutes,
  commonRoutes
} from '@/router/routerMain'
import $x from '@/libs/$x'

const state = {
  routes: [],
  addRoutes: [],
  routeIds: []
}

const mutations = {
  setRoutes: (state, routes) => {
    state.addRoutes = routes
    state.routes = commonRoutes.concat(routes)
  },
  setRouteIds(state, ids) {
    state.routeIds = ids
  }
}

const getters = {
  navMenu: (state) => {
    if (state.addRoutes) {
      console.log('state.addRoutes', state.addRoutes)
      return state.addRoutes
    }
    return []
  }
}

const blackList = {
  'emall':['mypet','categoryManage','projectManage'],
  'pet':[],
  'ec':['serviceOrderList','mypet','categoryManage','projectManage'],
}

const filterRouter = (routerData, idList) => {
  let result = []
  routerData.forEach(item => {
    if (idList.indexOf(item.meta.id) > -1) {
      if (item.meta.id !== 100) {
        item.meta.permit = true
      }
      if (item.children) {
        let children = filterRouter(item.children, idList)
        if (children.length > 0) {
          item.children = children
        }
      }
      if(blackList[$x.platformType].indexOf(item.name) === -1){
        result.push(item)
      }
    }
  })
  return result
}

const handleManageRouter = (oldRouter, resData) => {
  let pathList = []
  // 通过id找出对应的router path
  resData.forEach(item => {
    if (item.id !== 1) {
      let targetObj = $x.mapper.manageMenuMapper[item.id]
      if (targetObj && targetObj.path) {
        pathList.push(targetObj.path)
      }
      if (item.children && item.children.length > 0) {
        item.children.forEach(inner => {
          let targetObj = $x.mapper.manageMenuMapper[inner.id]
          if (targetObj && targetObj.path) {
            pathList.push(targetObj.path)
          }
        })
      }
    }
  })
  oldRouter.forEach(item => {
    if (pathList.indexOf(item.path) > -1) {
      item.meta.show = true
    }
    if (item.children) {
      item.children.forEach(inner => {
        if (pathList.indexOf(inner.path) > -1) {
          inner.meta.show = true
        }
      })
    }
  })
  return oldRouter
}

//判断是否为移动端
const isMobile = () => {
  return $x.isMobile
}

const isPC = () => {
  return $x.isPc
}

const filterPCRoutes = (routes) => {
  return routes.filter(route => {
    if (route.children) {
      route.children = filterPCRoutes(route.children);
      if (route.children.length > 0) {
        route.redirect = route.children[0].path;
      }
    }
    return !route.meta.showInPC;
  });
};

const fixedRedirectRouter = (routerList) => {
  routerList.forEach(item => {
    if (item.redirect && item.children && item.children.length > 0) {
      item.redirect = item.children[0].path
    }
  })
}

const actions = {
  //addRoutes若有 则设置路由为当前栈中首个路由
  toDefaultRouter({
    state,
    commit,
    dispatch
  }) {
    return new Promise((resolve, reject) => {
      console.log(state.addRoutes)
      if (state.addRoutes && state.addRoutes.length > 0) {
        router.push(state.addRoutes[0].path)
        // state.addRoutes.some(item => {
        //   if (item.meta.show) {
        //     router.push(item.path)
        //     return true
        //   }
        //   return false
        // })
        resolve()
      }
    })
  },
  toShopListRouter({
    state,
    commit,
    dispatch
  }) {
    return new Promise((resolve, reject) => {
      router.push('/shopList/index')
      resolve()
    })
  },
  //初始化路由
  initRouter({
    commit,
    dispatch
  }, { shopCommonSetting, roleData }) {
    return new Promise(async (resolve, reject) => {
      resetRouter()
      let accessedRoutes = []
      console.log('roleData', roleData)
      if (roleData !== 'M' && roleData) {
        const idList = roleData.map(item => item.id)
        idList.push(100)
        commit('setRouteIds', idList)
        accessedRoutes = filterRouter(shopRoutes, idList)
      } else {
        console.log(shopRoutes)
        accessedRoutes = shopRoutes
      }
      if (!isMobile()) {
        accessedRoutes = accessedRoutes.filter(item => !item.meta.showInMobile)
      }
      if (!isPC()) {
        accessedRoutes = filterPCRoutes(accessedRoutes);
        // accessedRoutes = accessedRoutes.filter(item => !item.meta.showInPC)
      }
      if(shopCommonSetting.enablePlatformIntervention === 0){
        accessedRoutes = accessedRoutes.filter(item => !item.meta.showByBtn)
      }
      console.log('accessedRoutes', accessedRoutes)
      fixedRedirectRouter(accessedRoutes)

      commit('setRoutes', accessedRoutes)
      router.addRoutes(accessedRoutes)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
